import { CollectType, ServiceCodeFee, getPennies } from '@dltru/dfa-models'
import { FormInstance } from '@dltru/dfa-ui'
import dayjs from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { authSelector } from '@store/auth/selectors'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { getCalculateFeeHelper } from '@store/helper'

import { AccountRefillInfo } from '@components/Modals/Orders/Trade/common/AccountRefillInfo'
import { BankAccountInfo } from '@components/Modals/Orders/Trade/common/BankAccountInfo'
import { LimitInfoBody } from '@components/Modals/Orders/Trade/common/LimitInfoBody'

import { SecondaryTradeBuyingModalFormBody } from './SecondaryTradeBuyingModalFormBody'

interface IComponentProps {
    setIsBodyTarget: any
    isBodyTarget: string
    isAmount: number
    isPricePerDfa: number
    setIsAmount: (val: number) => void
    setIsPricePerDfa: (val: number) => void
    setIsModalVisible: (val: boolean) => void
    buying?: boolean
    selling?: boolean
    accepted?: boolean
    form: FormInstance<any>
    assetId?: number
    orderId?: number
    rest_amount_dfa?: number
    // костыль
    isCreate?: boolean
    paymentCollectType?: CollectType
}

export const SecondaryTradeBuyingModalBody: FC<IComponentProps> = ({
    isAmount,
    setIsAmount,
    isPricePerDfa,
    setIsPricePerDfa,
    setIsBodyTarget,
    isBodyTarget,
    setIsModalVisible,
    buying,
    selling,
    accepted,
    form,
    assetId,
    orderId,
    rest_amount_dfa,
    isCreate,
    paymentCollectType,
}) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const user_id = useSelector(authSelector.selectUserUid)

    const [currentCollectType, setCurrentCollectType] = useState(paymentCollectType)
    const isBankCollectType = currentCollectType === CollectType.bank_account

    const [commission, setCommission] = useState(0)
    const [feePaymentOrder, setFeePaymentOrder] = useState(0)

    useEffect(() => {
        if (isCreate) {
            const code = selling ? ServiceCodeFee.sellDfa : ServiceCodeFee.buyDfa
            getCalculateFeeHelper({
                service_code: code,
                operation_amount: getPennies(isAmount * isPricePerDfa),
                user_id,
                asset_id: dfaDetails.id,
                start_date: dayjs(dfaDetails.issue_start_date).toISOString(),
                end_date: dayjs(dfaDetails.issue_end_date).toISOString(),
            }).then((fee) => {
                setCommission(fee.item.FeeAmount)
            })
        } else {
            const code = selling ? ServiceCodeFee.sellDfa : ServiceCodeFee.buyDfa
            getCalculateFeeHelper({
                service_code: code,
                operation_amount: getPennies(isAmount * isPricePerDfa),
                user_id,
                asset_id: dfaDetails.id,
                start_date: dayjs(dfaDetails.issue_start_date).toISOString(),
                end_date: dayjs(dfaDetails.issue_end_date).toISOString(),
            }).then((fee) => {
                setCommission(fee.item.FeeAmount)
            })

            if (isBankCollectType) {
                getCalculateFeeHelper({
                    service_code: ServiceCodeFee.paymentOrder,
                    user_id,
                    asset_id: dfaDetails.id,
                    start_date: dayjs(dfaDetails.issue_start_date).toISOString(),
                    end_date: dayjs(dfaDetails.issue_end_date).toISOString(),
                }).then((requestedFee) => {
                    setFeePaymentOrder(requestedFee.item.FeeAmount)
                })
            } else {
                setFeePaymentOrder(0)
            }
        }
    }, [isAmount, isPricePerDfa, isBankCollectType, isCreate, selling, buying])

    const submittingCommission = 100
    const currentDate = new Date()

    const changeBody = (target: string) => (e: React.SyntheticEvent) => {
        e.preventDefault()
        setIsBodyTarget(target)
    }

    return (
        <TransitionGroup className="slide-animation__block">
            {isBodyTarget === 'buying' && (
                <CSSTransition timeout={300} classNames="slide-animation">
                    <SecondaryTradeBuyingModalFormBody
                        dfaDetails={dfaDetails}
                        isAmount={isAmount}
                        isPricePerDfa={isPricePerDfa}
                        commission={commission}
                        feePaymentOrder={feePaymentOrder}
                        onChangeCollectType={paymentCollectType ? undefined : setCurrentCollectType}
                        submittingCommission={submittingCommission}
                        currentDate={currentDate}
                        setIsAmount={setIsAmount}
                        setIsPricePerDfa={setIsPricePerDfa}
                        changeBody={changeBody}
                        setIsModalVisible={setIsModalVisible}
                        buying={buying}
                        selling={selling}
                        accepted={accepted}
                        form={form}
                        assetId={assetId}
                        orderId={orderId}
                        rest_amount_dfa={rest_amount_dfa}
                        isCreate={isCreate}
                    />
                </CSSTransition>
            )}
            {isBodyTarget === 'info' && (
                <CSSTransition timeout={300} classNames="slide-animation">
                    <AccountRefillInfo changeBodyToBuying={changeBody('buying')} />
                </CSSTransition>
            )}
            {isBodyTarget === 'limit' && (
                <CSSTransition timeout={300} classNames="slide-animation">
                    <LimitInfoBody changeBodyToBuying={changeBody('buying')} />
                </CSSTransition>
            )}
            {isBodyTarget === 'bankDetails' && (
                <CSSTransition timeout={300} classNames="slide-animation">
                    <BankAccountInfo changeBodyToBuying={changeBody('buying')} />
                </CSSTransition>
            )}
        </TransitionGroup>
    )
}
