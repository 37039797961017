import { CertificateType, CollectType, ProfileType } from '@dltru/dfa-models'
import {
    BankCollectCommission,
    BodyCheck,
    BodyDate,
    BodyRoundedBlock,
    BodyTitle,
    Box,
    Button,
    Space,
    Alert,
} from '@dltru/dfa-ui'
import { FC, useState } from 'react'

import { ConditionTradeLink } from '@components/ConditionTradeLink'
import { AccountLimitBlock } from '@components/Modals/Orders/Trade/common/AccountLimitBlock'
import { CertificateListModalWithTransactionData } from '@components/Modals/Certificate/CertificateListModalWithTransactionData'

interface IComponentProps {
    isAmount: number
    isPricePerDfa: number
    commission?: number
    submittingCommission?: number
    currentDate: Date
    request: any
    sign: any
    closeModal: any
    loading: boolean
    buying?: boolean
    paymentCollectType?: CollectType
    buyByIssuer?: boolean
    accepted?: boolean
    isCreate?: boolean
    feePaymentOrder?: number
    profileTypes?: (ProfileType.PRSN | ProfileType.LEGL | ProfileType.BUSN)[]
}

export const SecondaryTradeBuyingModalApproveBody: FC<IComponentProps> = ({
    isAmount,
    isPricePerDfa,
    currentDate,
    request,
    sign,
    closeModal,
    loading,
    buying,
    paymentCollectType,
    buyByIssuer,
    accepted,
    commission,
    isCreate,
    feePaymentOrder,
    profileTypes,
}) => {
    const [isVisibleCertificateModal, setIsVisibleCertificateModal] = useState(false)
    const closeThisModal = (e: React.SyntheticEvent): void => {
        e.preventDefault()
        closeModal(false)
    }

    const prefixCommissionLabel = isCreate ? 'Максимальная комиссия' : 'Комиссия'
    const directionCommissionLabel = buying ? 'за покупку ЦФА' : 'за продажу ЦФА'
    const commissionLabel = `${prefixCommissionLabel} ${directionCommissionLabel} ${
        feePaymentOrder ? '*' : ''
    }`
    const commissionSum = ((commission ?? 0) + (feePaymentOrder ?? 0)) / 100
    const checkList = [
        {
            label: 'Цена',
            price: isPricePerDfa,
        },
        {
            label: buying ? 'Количество, ед.' : 'Доступно к отчуждению, ед.',
            price: isAmount,
            currency: 'unit',
        },
        {
            label: 'Стоимость активов',
            price: isAmount * isPricePerDfa,
            //currency: 'unit',
        },
        {
            label: commissionLabel,
            price: commissionSum,
            //currency: 'unit',
        },
        {
            label: 'Итого',
            price: isAmount * isPricePerDfa + commissionSum,
            //currency: 'unit',
        },
    ].map((check) => (
        <BodyCheck
            label={check.label}
            labelRight={false}
            price={check.price}
            currency={check.currency}
            key={check.label + check.price}
        />
    ))

    const onSubscribe = (certificate: CertificateType) => {
        request(certificate.skid)
    }

    const onSign = (certificate: CertificateType) => {
        sign(certificate.skid)
    }

    return (
        <div style={{ background: '#ffffff' }}>
            <BodyTitle title={`Детали ${buying ? 'покупки' : 'продажи'}`} />
            <div>{checkList}</div>
            {profileTypes?.includes(ProfileType.PRSN) && !buying && paymentCollectType !== CollectType.account &&
              <Box margin={[16, 0]}>
                <Alert 
                  showIcon 
                  type="warning"
                  description="В момент совершения сделки будет удержан НДФЛ, рассчитанный с налоговой базы за текущий налоговый период."
                />
              </Box>
            }
            <BankCollectCommission feePaymentOrder={feePaymentOrder} />
            <Box padding={[0, 0, 16, 0]}>
                <BodyRoundedBlock
                    left={
                        <>
                            <p className="card-modal__body-text--12">Подача заявки на покупку</p>
                            <BodyDate date={currentDate || new Date()} />
                        </>
                    }
                    right={
                        <>
                            {/*<p className="card-modal__body-text--12">будет исполнено</p>
                            <p className="card-modal__body-text--16">в момент выпуска ЦФА</p>*/}
                        </>
                    }
                />
            </Box>
            <Box padding={[0, 0, 16, 0]}>
                <AccountLimitBlock paymentCollectType={paymentCollectType} />
            </Box>
            <Box padding={[0, 0, 24, 0]} align="center">
                <p className="card-modal__body-text--12">
                    <ConditionTradeLink
                        isBuying={buying}
                        buyByIssuer={buyByIssuer}
                        collectType={paymentCollectType}
                        isSecond
                        accepted={accepted}
                    />
                </p>
            </Box>
            <Box padding={[24, 0, 0, 0]} align="end">
                <Space size={16}>
                    <Button
                        size="large"
                        borderRadius={16}
                        onClick={closeThisModal}
                        disabled={loading}
                    >
                        Отмена
                    </Button>
                    <Button
                        size="large"
                        type="primary"
                        borderRadius={16}
                        onClick={() => {
                            setIsVisibleCertificateModal(true)
                        }}
                        disabled={loading}
                    >
                        Подтвердить заявку
                    </Button>
                </Space>
            </Box>
            <CertificateListModalWithTransactionData
                isModalVisible={isVisibleCertificateModal}
                setIsModalVisible={setIsVisibleCertificateModal}
                onSubscribe={onSubscribe}
                onSign={onSign}
            />
        </div>
    )
}
