import { CollectType } from '@dltru/dfa-models'
import { CardModal, Form, getModalTradeHeaderData } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadDfaAvailableDeals } from '@store/dfa/availableDeals'
import { dfaAvailableDealsSelector } from '@store/dfa/availableDeals/selectors'
import { getDfaBalanceById, getDfaById } from '@store/dfa/details'
import { dfaDetailsSelector } from '@store/dfa/details/selectors'
import { loadsetAccountMoneyByUser } from '@store/money'
import { profileDetailsSlice } from '@store/profiles/details'
import { profileSelector } from '@store/profiles/details/selectors'

import { SecondaryTradeBuyingModalBody } from './SecondaryTradeBuyingModalBody'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    amount?: number
    price_per_dfa?: number
    rest_amount_dfa?: number
    buying?: boolean
    selling?: boolean
    accepted?: boolean
    assetId?: number
    orderId?: number
    isCreate?: boolean
    paymentCollectType?: CollectType
}

export const SecondaryTradeBuyingModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    amount,
    price_per_dfa,
    buying,
    selling,
    accepted,
    assetId,
    orderId,
    rest_amount_dfa,
    isCreate,
    paymentCollectType,
}) => {
    const dfaDetails = useSelector(dfaDetailsSelector.selectDfaDetails)
    const isCheckQuota = useSelector(profileSelector.isCheckQuota)
    const dfaAvailableRestAmount = useSelector(dfaAvailableDealsSelector.selectRestAmountDfa)
    const [isBodyTarget, setIsBodyTarget] = useState('buying')
    const [isAmount, setIsAmount] = useState(amount || 0)
    const [isPricePerDfa, setIsPricePerDfa] = useState(price_per_dfa || 0)

    const reduxDispatch = useDispatch()
    const [form] = Form.useForm()

    useEffect(() => {
        if (isModalVisible) {
            if (typeof assetId === 'number' && dfaDetails?.id !== assetId) {
                reduxDispatch(getDfaById({ dfaId: assetId }))
            }
            if (dfaDetails.id !== undefined) {
                reduxDispatch(loadDfaAvailableDeals())
            }
            reduxDispatch(loadsetAccountMoneyByUser())
            if (isCheckQuota) {
                reduxDispatch(profileDetailsSlice.actions.getQualifyInfo())
            }
            setIsPricePerDfa(price_per_dfa || 0)
            if (isAmount) {
                form.setFieldsValue({ isAmount })
            }
            if (isPricePerDfa) {
                form.setFieldsValue({ isPricePerDfa })
            }
            if (assetId) {
                reduxDispatch(getDfaBalanceById({ dfaId: assetId }))
            }
        }
    }, [isModalVisible, assetId])

    const changeModalVisible = (val: boolean) => {
        setIsModalVisible(val)
        setIsBodyTarget('buying')
        if (!val) {
            setIsAmount(0)
            setIsPricePerDfa(0)
        }
        form.resetFields()
    }

    return (
        <CardModal
            isModalVisible={isModalVisible}
            setIsModalVisible={changeModalVisible}
            headerData={getModalTradeHeaderData(dfaDetails)}
            buying={buying}
            selling={selling}
            mini={isBodyTarget === 'approve'}
            closable
            width={540}
            destroyOnClose
            BodyRenderProps={
                <SecondaryTradeBuyingModalBody
                    isAmount={isAmount}
                    setIsAmount={setIsAmount}
                    isPricePerDfa={isPricePerDfa}
                    setIsPricePerDfa={setIsPricePerDfa}
                    isBodyTarget={isBodyTarget}
                    setIsBodyTarget={setIsBodyTarget}
                    setIsModalVisible={changeModalVisible}
                    buying={buying}
                    selling={selling}
                    accepted={accepted}
                    form={form}
                    assetId={assetId}
                    orderId={orderId}
                    isCreate={isCreate}
                    rest_amount_dfa={rest_amount_dfa || dfaAvailableRestAmount}
                    paymentCollectType={paymentCollectType}
                />
            }
        />
    )
}
