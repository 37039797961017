import { CollectType, IDfaFront, stringToNumber } from '@dltru/dfa-models'
import {
    BankCollectCommission,
    BodyCheck,
    BodyDate,
    BodyInputBlock,
    BodyRoundedBlock,
    Box,
    CurrencyInput,
    Form,
    FormInstance,
    IntegerInput,
    TradeButton,
    TwoColumns,
    commonValidators,
    dfaTransferValidators,
} from '@dltru/dfa-ui'
import { FieldData } from 'rc-field-form/lib/interface'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import { moneySelector } from '@store/money/selectors'
import { profileSelector } from '@store/profiles/details/selectors'

import { AccountLimitBlock } from '@components/Modals/Orders/Trade/common/AccountLimitBlock'

import { SecondaryTradeBuyingApproveModal } from '../../SecondaryTradeBuyingApproveModal'

interface IComponentProps {
    dfaDetails: IDfaFront
    isAmount: number
    isPricePerDfa: number
    commission?: number
    feePaymentOrder?: number
    submittingCommission: number
    currentDate: Date
    setIsAmount: (val: number) => void
    setIsPricePerDfa: (val: number) => void
    changeBody: (target: string) => (e: React.SyntheticEvent) => void
    onChangeCollectType?: (collectType?: CollectType) => void
    setIsModalVisible: (val: boolean) => void
    buying?: boolean
    selling?: boolean
    accepted?: boolean
    form: FormInstance<any>
    assetId?: number
    orderId?: number
    rest_amount_dfa?: number
    // костыль
    isCreate?: boolean
}

export const SecondaryTradeBuyingModalFormBody: FC<IComponentProps> = ({
    dfaDetails,
    isAmount,
    isPricePerDfa,
    commission,
    feePaymentOrder,
    submittingCommission,
    currentDate,
    setIsAmount,
    setIsPricePerDfa,
    changeBody,
    setIsModalVisible,
    buying,
    selling,
    accepted,
    form,
    assetId,
    orderId,
    rest_amount_dfa,
    isCreate,
    onChangeCollectType,
}) => {
    const moneyAccount = useSelector(moneySelector.selectAccount)
    const isBlockUnqual = useSelector(profileSelector.selectBlockUnqual)
    const maxLimit = useSelector(profileSelector.selectLimitRublies)
    const userUid = useSelector(authSelector.selectUserUid)

    const buyByIssuer = buying && dfaDetails.emitter_id === userUid && assetId === dfaDetails.id

    const handleInputAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAmount(stringToNumber(event.target.value))
    }
    const handleInputOriginalPriceAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPricePerDfa(stringToNumber(event.target.value))
        form.validateFields(['isAmount'])
    }

    const [isCardModalApproveVisible, setIsCardModalApproveVisible] = useState(false)

    const openCardModal = (): void => {
        setIsCardModalApproveVisible(true)
    }

    const [restAmount, setRestAmount] = useState(rest_amount_dfa || 0)

    const [hasIsAmountFieldError, setHasIsAmountFieldError] = useState(false)

    // TODO сделать проверки на все варианты

    useEffect(() => {
        let availableAmount
        if (!accepted && selling) {
            availableAmount = dfaDetails.balance?.active_amount_dfa ?? 0
        } else if (!accepted && buying) {
            availableAmount = dfaDetails.total_supply_invested
        } else if (accepted && selling && rest_amount_dfa) {
            availableAmount = Math.min(rest_amount_dfa, dfaDetails.balance?.active_amount_dfa ?? 0)
        } else {
            availableAmount = rest_amount_dfa
        }
        setRestAmount(availableAmount || 0)
    }, [rest_amount_dfa, accepted, dfaDetails, buying, selling])

    const [isErrorForm, setIsErrorForm] = useState(false)
    const onFieldsChange = (_, allFields: FieldData[]) => {
        setIsErrorForm(allFields?.some(({ errors }) => errors?.length))
        const isAmountFieldError = allFields.find(
            (field: any) => field.name[0] === 'isAmount' && field.errors?.length,
        )
        setHasIsAmountFieldError(Boolean(isAmountFieldError))
    }
    const currentAccountMoney = (moneyAccount && moneyAccount.balance.available / 100) || 0
    const commissionSum = ((commission ?? 0) + (feePaymentOrder ?? 0)) / 100
    const fullAmount = commissionSum + isAmount * isPricePerDfa

    const isDisabledBtn =
        isBlockUnqual ||
        !isAmount ||
        !isPricePerDfa ||
        isErrorForm ||
        currentAccountMoney < (buying ? fullAmount : commissionSum)

    const prefixCommissionLabel = isCreate ? 'Максимальная комиссия' : 'Комиссия'
    const directionCommissionLabel = buying ? 'за покупку ЦФА' : 'за продажу ЦФА'
    const commissionLabel = `${prefixCommissionLabel} ${directionCommissionLabel} ${
        feePaymentOrder ? '*' : ''
    }`

    return (
        <div style={{ background: '#ffffff' }}>
            <Form
                form={form}
                name="tradeModal"
                onFinish={openCardModal}
                onFieldsChange={onFieldsChange}
            >
                <TwoColumns gap="0 32px">
                    <BodyInputBlock
                        title="Количество"
                        explain={!hasIsAmountFieldError && `Доступно ${restAmount}`}
                    >
                        <IntegerInput
                            name="isAmount"
                            rules={[
                                {
                                    validator: dfaTransferValidators.maxSum({
                                        pricePerDFA: Number(isPricePerDfa),
                                        maxMoney: buying ? currentAccountMoney : undefined,
                                        maxCount: restAmount,
                                        maxLimit: buying ? maxLimit : undefined,
                                    }),
                                },
                            ]}
                            onChange={handleInputAmountChange}
                            required
                        />
                    </BodyInputBlock>
                    <BodyInputBlock read={accepted} title="Цена за ед." value={isPricePerDfa}>
                        {!accepted && (
                            <CurrencyInput
                                name="isPricePerDfa"
                                rules={[
                                    {
                                        validator: commonValidators.positive(),
                                    },
                                ]}
                                onChange={handleInputOriginalPriceAmountChange}
                                required
                            />
                        )}
                    </BodyInputBlock>
                </TwoColumns>

                <Box padding={[8, 0, 0, 0]}>
                    <BodyCheck
                        label="Стоимость активов"
                        price={isAmount * isPricePerDfa}
                        labelRight
                    />
                </Box>

                <Box padding={[8, 0, 0, 0]}>
                    <BodyCheck label={commissionLabel} price={commissionSum} labelRight />
                </Box>

                <Box padding={[8, 0, 0, 0]}>
                    <BodyCheck label="Итого" price={fullAmount} labelRight />
                </Box>

                <BankCollectCommission feePaymentOrder={feePaymentOrder} />

                <Box padding={[8, 0]}>
                    <BodyRoundedBlock
                        left={
                            <>
                                <p className="card-modal__body-text--12">
                                    {accepted ? 'Заявка направлена' : 'Публикация на витрине'}
                                </p>
                                <BodyDate date={currentDate} />
                            </>
                        }
                    />
                </Box>

                <Box padding={[0, 0, 24, 0]}>
                    <AccountLimitBlock
                        actionAccount={changeBody('info')}
                        actionLimit={buying ? changeBody('limit') : undefined}
                        actionBankDetails={selling ? changeBody('bankDetails') : undefined}
                        onChangeCollectType={onChangeCollectType}
                    />
                </Box>

                <Box padding={[24, 0, 0, 0]} align="center">
                    <TradeButton
                        color={buying ? 'green' : 'red'}
                        onClick={form.submit}
                        disabled={isDisabledBtn}
                        label={buying ? 'Купить' : 'Продать'}
                    />

                    {buyByIssuer && (
                        <Box padding={[16, 0, 0, 0]} align="center">
                            <p className="card-modal__body-text--12">
                                Вы являетесь Эмитентом данного выпуска ЦФА.
                            </p>
                        </Box>
                    )}
                </Box>
            </Form>

            <SecondaryTradeBuyingApproveModal
                isModalVisible={isCardModalApproveVisible}
                setIsModalVisible={setIsCardModalApproveVisible}
                setIsParentModalVisible={setIsModalVisible}
                dfaDetails={dfaDetails}
                isAmount={isAmount}
                isPricePerDfa={isPricePerDfa}
                commission={commission}
                feePaymentOrder={feePaymentOrder}
                submittingCommission={submittingCommission}
                currentDate={currentDate}
                buying={buying}
                selling={selling}
                accepted={accepted}
                assetId={assetId}
                orderId={orderId}
                paymentCollectType={form.getFieldValue('payment_collect_type')}
                isCreate={isCreate}
            />
        </div>
    )
}
