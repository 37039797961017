/* eslint-disable unused-imports/no-unused-vars */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { CollectType } from '@dltru/dfa-models'
import { CardModal, getModalTradeHeaderData } from '@dltru/dfa-ui'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authSelector } from '@store/auth/selectors'
import {
    postSecondaryBuyAcceptDfa,
    postSecondaryBuyAcceptDfaPrefly,
    postSecondaryBuyDfa,
    postSecondaryBuyDfaPrefly,
    postSecondarySellAcceptDfa,
    postSecondarySellAcceptDfaPrefly,
    postSecondarySellDfa,
    postSecondarySellDfaPrefly,
} from '@store/secondaryDfa/details'
import IAppState from '@store/states'

import { SecondaryTradeBuyingModalApproveBody } from './SecondaryTradeBuyingModalApproveBody'
import { profileSelector } from '@store/profiles/details/selectors'

interface IComponentProps {
    isModalVisible: boolean
    setIsModalVisible: (val: boolean) => void
    setIsParentModalVisible: (val: boolean) => void
    dfaDetails: any
    isAmount: number
    isPricePerDfa: number
    commission?: number
    feePaymentOrder?: number
    submittingCommission: number
    currentDate: Date
    buying?: boolean
    selling?: boolean
    accepted?: boolean
    assetId?: number
    orderId?: number
    paymentCollectType?: CollectType
    isCreate?: boolean
}

export const SecondaryTradeBuyingApproveModal: FC<IComponentProps> = ({
    isModalVisible,
    setIsModalVisible,
    setIsParentModalVisible,
    dfaDetails,
    isAmount,
    isPricePerDfa,
    commission,
    feePaymentOrder,
    submittingCommission,
    currentDate,
    buying,
    selling,
    accepted,
    assetId,
    orderId,
    paymentCollectType,
    isCreate,
}) => {
    const closeModal = (val: boolean) => {
        setIsModalVisible(val)
    }

    const reduxDispatch = useDispatch()
    const secondaryDfaDetails = useSelector((store: IAppState) => store.secondaryDfaDetails)
    const userUid = useSelector(authSelector.selectUserUid)
    const { uuid: orderUuid } = useSelector((store: IAppState) => store.orderDetails)
    const profileTypes = useSelector(profileSelector.selectProfileTypes)

    const buyByIssuer = buying && dfaDetails.emitter_id === userUid
    const [isCardModalVisible, setIsCardModalVisible] = useState(false)
    const [isStartLoading, setIsStartLoading] = useState(false)

    const commisionKey = buying ? 'buy_fee_amount' : 'sell_fee_amount'

    const postSecondaryDfaData = (skid: string) => {
        if (accepted) {
            if (orderId !== undefined && userUid) {
                const payload = {
                    uuid: orderUuid,
                    user_id: userUid,
                    amount_dfa: +isAmount,
                    payment_collect_type: paymentCollectType,
                    skid,
                    [commisionKey]: commission,
                }
                if (buying) {
                    reduxDispatch(postSecondaryBuyAcceptDfaPrefly(payload))
                } else {
                    reduxDispatch(postSecondarySellAcceptDfaPrefly(payload))
                }
            }
        } else {
            const payload = {
                user_id: userUid || '',
                asset_id: assetId,
                original_amount_dfa: +isAmount,
                price_per_dfa: +isPricePerDfa,
                payment_collect_type: paymentCollectType,
                skid,
                [commisionKey]: commission,
            }
            if (buying) {
                reduxDispatch(postSecondaryBuyDfaPrefly(payload))
            } else {
                reduxDispatch(postSecondarySellDfaPrefly(payload))
            }
        }
    }

    const signTransaction = (skid: string) => {
        if (accepted) {
            const payload = {
                skid,
                uuid: orderUuid,
                user_id: userUid,
                amount_dfa: +isAmount,
                payment_collect_type: paymentCollectType,
                [commisionKey]: commission,
            }
            if (buying) {
                reduxDispatch(postSecondaryBuyAcceptDfa(payload))
            } else {
                reduxDispatch(postSecondarySellAcceptDfa(payload))
            }
        } else {
            const payload = {
                user_id: userUid || '',
                asset_id: assetId,
                original_amount_dfa: +isAmount,
                price_per_dfa: +isPricePerDfa,
                payment_collect_type: paymentCollectType,
                skid,
                [commisionKey]: commission,
            }
            if (buying) {
                reduxDispatch(postSecondaryBuyDfa(payload))
            } else {
                reduxDispatch(postSecondarySellDfa(payload))
            }

        }
    }

    useEffect(() => {
        if (secondaryDfaDetails.isLoading) {
            setIsStartLoading(true)
        } else if (isStartLoading && !secondaryDfaDetails.isLoading) {
            setIsStartLoading(false)
            setIsModalVisible(false)
            setIsParentModalVisible(false)
            setTimeout(() => {
                setIsCardModalVisible(true)
            }, 0)
        }
    }, [secondaryDfaDetails])

    return (
        <>
            <CardModal
                isModalVisible={isModalVisible}
                setIsModalVisible={closeModal}
                headerData={getModalTradeHeaderData({
                    ...dfaDetails,
                    emitter_full_name: secondaryDfaDetails.emitter_full_name,
                })}
                secondModal
                buying={buying}
                selling={selling}
                mini
                width={540}
                BodyRenderProps={
                    <SecondaryTradeBuyingModalApproveBody
                        isAmount={isAmount}
                        isPricePerDfa={isPricePerDfa}
                        commission={commission}
                        submittingCommission={submittingCommission}
                        currentDate={currentDate}
                        request={postSecondaryDfaData}
                        sign={signTransaction}
                        buying={buying}
                        closeModal={closeModal}
                        loading={secondaryDfaDetails.isLoading}
                        paymentCollectType={paymentCollectType}
                        buyByIssuer={buyByIssuer}
                        accepted={accepted}
                        isCreate={isCreate}
                        feePaymentOrder={feePaymentOrder}
                        profileTypes={profileTypes ?? undefined}
                    />
                }
            />
            {/*<SecondaryOrderDetailsModal
                isModalVisible={isCardModalVisible}
                setIsModalVisible={setIsCardModalVisible}
                order={secondaryDfaDetails}
            />*/}
        </>
    )
}
